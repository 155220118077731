import { Alert, Typography, useTheme } from '@ori-ui/mui';
import type { ArticlesSectionProps } from '@ori/editorial-components';
import { ArticlesSection, CategoriesSection } from '@ori/editorial-components';
import { useGetEditorialCategoryOrPagePreviewData } from '@ori/editorial-fetching';
import useTestId from '@ori/testid-generator';
import { useCallback, type FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { CategoryPageSkeleton } from '../CategoryPageSkeleton';
import { Description, Root, TopBanner } from '../LandingPage/styles';
import { CATEGORY_PAGE } from './constants';
import type { CategoryPageProps } from './types';

/**
 * Component that renders content on editorial category page.
 */
export const CategoryPage: FC<CategoryPageProps> = ({ breadcrumbs, data: ssgData, pageId }) => {
  const { palette } = useTheme();
  const { formatMessage, translations } = useTranslations();
  const { getTestId } = useTestId();
  const getPaginationInfoText = useCallback<NonNullable<ArticlesSectionProps['getPaginationInfoText']>>(
    (value, max) => formatMessage('showingXOutOfYProducts', { value, max }),
    [formatMessage],
  );
  const { categoryPreviewData, previewMode, loaded } = useGetEditorialCategoryOrPagePreviewData({
    pageId,
  });
  const showPreviewData = previewMode && loaded;
  const data = showPreviewData ? categoryPreviewData : ssgData;

  const { editorialLandingPage, editorialLandingPages } = data ?? {};
  if (!editorialLandingPage) {
    return <CategoryPageSkeleton />;
  }
  const { themeTag, description, editorialItems, imageUrl } = editorialLandingPage;

  return (
    <Root data-testid={getTestId(CATEGORY_PAGE)}>
      {showPreviewData ? (
        <Alert
          color="success"
          severity="info"
        >
          <Typography>PREVIEW MODE</Typography>
        </Alert>
      ) : null}

      <TopBanner
        showTitleOnImageBackground
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        textColor={palette.common.white}
        title={themeTag}
      />
      <Description description={description} />
      <ArticlesSection
        articles={editorialItems}
        countOfArticles={editorialItems?.length}
        labelRead={translations.readNow}
        loadMoreButtonText={translations.loadMore}
        mode="listing"
        title=""
        getPaginationInfoText={getPaginationInfoText}
      />
      <CategoriesSection
        categories={editorialLandingPages}
        title={translations.otherCategories}
        viewButtonText={translations.view}
      />
    </Root>
  );
};
