import type { SkeletonProps } from '@ori/editorial-components';
import { ArticlesSectionSkeleton, CategoriesSectionSkeleton } from '@ori/editorial-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { Root } from '../LandingPage/styles';
import { LANDING_PAGE_SKELETON } from './constants';
import { TopBannerSkeleton, DescriptionSkeleton } from './styles';

/**
 * Renders Skeleton for `LandingPage`.
 *
 * @deprecated This component is deprecated as we are using SSG. Will be removed in next update. DO NOT USE.
 */
export const LandingPageSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();

  return (
    <Root
      className={className}
      data-testid={getTestId(LANDING_PAGE_SKELETON)}
    >
      <TopBannerSkeleton animation={animation} />
      <DescriptionSkeleton animation={animation} />
      <CategoriesSectionSkeleton animation={animation} />
      <ArticlesSectionSkeleton
        showTitle
        animation={animation}
        mode="featured"
      />
    </Root>
  );
};
