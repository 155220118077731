import { Team } from '@ori/testid-generator';

/**
 * Name of this app.
 */
export const APP_NAME = 'editorial-category';
export const APPLICATION_NAME = `@ori/${APP_NAME}`;

/**
 * Team which owns this SPA.
 */
export const APP_TEAM_NAME: Team = Team.Presentation;

export const DEFAULT_EDITORIALS_PATHNAME = '/campaigns/editorials';
