import { styled } from '@ori-ui/mui';
import { Description as DescriptionComponent } from '@ori/editorial-components';
import { TopBanner as TopBannerComponent } from '@ori/presentation-components';

export const Root = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  padding: theme.spacing(0, 3),
}));

export const TopBanner = styled(TopBannerComponent)(({ theme }) => ({
  margin: theme.spacing(0, -3, 3, -3),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, -3, 6, -3),
  },
}));

export const Description = styled(DescriptionComponent)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(10),
  },
}));
