import { styled } from '@ori-ui/mui';
import { DescriptionSkeleton as DescriptionSkeletonComponent } from '@ori/editorial-components';
import { TopBannerSkeleton as TopBannerSkeletonComponent } from '@ori/presentation-components';

/**
 * @deprecated This component is deprecated as we are using SSG. Will be removed in next update. DO NOT USE.
 */
export const TopBannerSkeleton = styled(TopBannerSkeletonComponent)(({ theme }) => ({
  margin: theme.spacing(0, -3, 3, -3),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, -3, 6, -3),
  },
}));
/**
 * @deprecated This component is deprecated as we are using SSG. Will be removed in next update. DO NOT USE.
 */
export const DescriptionSkeleton = styled(DescriptionSkeletonComponent)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(10),
  },
}));
