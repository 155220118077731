import { useTheme } from '@ori-ui/mui';
import { ArticlesSection, CategoriesSection } from '@ori/editorial-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { LandingPageSkeleton } from '../LandingPageSkeleton';
import { LANDING_PAGE } from './constants';
import { Description, Root, TopBanner } from './styles';
import type { LandingPageProps } from './types';

/**
 * Component that renders content on editorial landing page.
 */
export const LandingPage: FC<LandingPageProps> = ({ breadcrumbs, data }) => {
  const { palette } = useTheme();
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const { editorialLandingPage, editorialLandingPages } = data?.application ?? {};
  if (!editorialLandingPage) {
    return <LandingPageSkeleton />;
  }
  const { themeTag, description, editorialItems, imageUrl } = editorialLandingPage;

  return (
    <Root data-testid={getTestId(LANDING_PAGE)}>
      <TopBanner
        showTitleOnImageBackground
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        textColor={palette.common.white}
        title={themeTag}
      />
      <Description description={description} />
      <CategoriesSection
        categories={editorialLandingPages}
        title={translations.categories}
        viewButtonText={translations.view}
      />
      <ArticlesSection
        articles={editorialItems}
        countOfArticles={editorialItems?.length}
        labelRead={translations.readNow}
        mode="featured"
        title={translations.inspirationCorner}
      />
    </Root>
  );
};
